/* You can add global styles to this file, and also import other style files */

$gutter: 1rem; //for primeflex grid system
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* Demos */
@import "assets/demo/styles/flags/flags.css";
@import "assets/demo/styles/badges.scss";
@import "assets/demo/styles/code.scss";


.box-table-nodata{
    height: 200px;
    background-color: white;
    text-align: center;
}

.vnpt-field-set {
    .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
        padding: 0.5rem;
    }
    // .p-fieldset .p-fieldset-legend{
    //     // margin-left: 92%;
    // }
    .p-panel .p-panel-header{
        background: #ffff;
    }
    .report-param-required-error {
        label  {
            margin-top: -1.15rem;
        }
    }
}

.qlda .p-breadcrumb,.col-7,.col-5{
    border: none;
    padding: 0;
}

.hidden{
    visibility: hidden;
    height: 0px;
    padding: 0px;
    margin: 0px;
}

.text-error-field{
    margin-top: -15px !important;
}

.button-search{
    width: 36px !important;
    height: 36px !important;
    padding: 0 !important;
}

.button-toggle-column, .button-search{
    .pi{
        font-size: 24px;
    }
}

.dialog-custom{
    .p-dialog{
        position: absolute;
        top: 120px !important;
    }
    .p-dialog-content{
        overflow-y:unset;
    }
}

.icon-help-dashboard-template{
    position: absolute;
    top: 10px;
    right: 10px;
}